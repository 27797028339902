import React, { useState } from "react";
import validator from "validator";

const StepSeven = ({ nextStep, handleFormData, values, prevStep }) => {
    const [error, setError] = useState(false);

    const submitFormData = (e) => {
        e.preventDefault();

        if (
            validator.isEmpty(values.lastname)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <form onSubmit={submitFormData}>
                <div className={'max-w-screen-sm mx-auto bg-white p-5 rounded-2xl shadow-[5px_5px_rgba(163,_30,_52,_1),_10px_10px_rgba(163,_30,_52,_0.6),_15px_15px_rgba(163,_30,_52,_0.4),_20px_20px_rgba(163,_30,_52,_0.2),_25px_25px_rgba(163,_30,_52,_0.1)]'}>
                    <div className="max-w-screen-xl mx-auto text-center">
                        <div className="mb-10">
                            <h2 className="mb-2 text-3xl text-gray-500 font-montserratLight max-lg:text-xl">Please tell us how we can reach you about your free consultation</h2>
                            <span className="font-montserratLight">(7/8)</span>
                        </div>
                        <div className="w-full font-montserratBold">
                            <label htmlFor="name">LastName</label>
                        </div>
                        <div className="w-full">
                            <input id="name" type="text" className={`${error ? "placeholder:text-red-500" : ""} font-montserratRegular border border-black w-full p-2`} required name="lastname"
                                defaultValue={values.lastname}
                                placeholder={error ? (`This is a required field`) : (``)}
                                onChange={handleFormData("lastname")} />
                        </div>
                    </div>
                    <div className="flex justify-between w-full mt-5">
                        <button variant="primary" className="bg-red-600 text-white font-montserratRegular py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                            Previous
                        </button>
                        <button variant="primary" className="bg-green-600 text-white font-montserratRegular py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                            Continue
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default StepSeven