import React, { useState } from "react";
import StepOne from "./Steps/stepOne";
import StepTwo from "./Steps/stepTwo";
import StepThree from "./Steps/stepThree";
import StepFour from "./Steps/stepFour";
import StepFive from "./Steps/stepFive";
import StepSix from "./Steps/stepSix";
import StepSeven from "./Steps/stepSeven";
import StepEight from "./Steps/stepEight";
import Final from "./Steps/final";
import Logo from '../assets/img/KAASS-LAW-New-Large-e1478890892251.png'

export default function MultiSteps() {
    const [step, setstep] = useState(1);

    const [formData, setFormData] = useState({
        accident: "",
        lawyer: "",
        fault: "",
        accidentmessage: "",
        time: "",
        firstname: "",
        lastname: "",
        tel: "",
    });

    const nextStep = () => {
        setstep(step + 1);
    };

    const prevStep = () => {
        setstep(step - 1);
    };

    const handleInputData = (input) => (e) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [input]: value
        }));
    };

    switch (step) {
        case 1:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepOne
                                nextStep={nextStep}
                                handleFormData={handleInputData}
                                values={formData}
                            />
                        </div>
                    </div>
                </>
            );
        case 2:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepTwo
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData}
                            />
                        </div>
                    </div>
                </>
            );
        case 3:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepThree
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );
        case 4:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepFour
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );
        case 5:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepFive
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );
        case 6:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepSix
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );
        case 7:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepSeven
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );
        case 8:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <StepEight
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleFormData={handleInputData}
                                values={formData} />
                        </div>
                    </div>
                </>
            );
        case 9:
            return (
                <>
                    <section className="bg-[#7A7A7A] h-[300px]">
                        <div className="flex justify-center h-full items-center">
                            <img src={Logo} alt="logo" />
                        </div>
                    </section>;
                    <div className="max-w-screen-xl mx-auto max-lg:px-10">
                        <div className="mt-5">
                            <Final
                                prevStep={prevStep}
                                values={formData} />
                        </div>
                    </div>
                </>
            );
        default:
            return <section className="bg-[#7A7A7A] h-[300px]">
                <div className="flex justify-center h-full items-center">
                    <img src={Logo} alt="logo" />
                </div>
            </section>;
    }
}