import React, { useState } from "react";
import validator from "validator";

const StepTwo = ({ nextStep, handleFormData, values, prevStep }) => {
    const [error, setError] = useState(false);

    const submitFormData = (e) => {
        e.preventDefault();

        if (
            validator.isEmpty(values.lawyer)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <form onSubmit={submitFormData}>
                <div className={'max-w-screen-sm mx-auto bg-white p-5 rounded-2xl shadow-[5px_5px_rgba(163,_30,_52,_1),_10px_10px_rgba(163,_30,_52,_0.6),_15px_15px_rgba(163,_30,_52,_0.4),_20px_20px_rgba(163,_30,_52,_0.2),_25px_25px_rgba(163,_30,_52,_0.1)]'}>
                    <div className="max-w-screen-xl mx-auto text-center">
                        <div className="mb-10">
                            <h2 className="mb-2 text-3xl font-montserratMedium max-lg:text-xl">Do You already have a lawyer or a payout from this accident?</h2>
                            <span className="font-montserratLight">(2/8)</span>
                        </div>
                        <main className="flex w-full items-center justify-center">
                            <div className="grid grid-cols-1 w-full rounded-xl bg-gray-200 p-2" x-data="app">
                                <div>
                                    <input type="radio" name="option" id={1} className="peer hidden" required onChange={handleFormData("lawyer")} value={'No I need an injury attorney'} />
                                    <label htmlFor={1} className={`${error ? "text-red-500" : ""} block font-montserratRegular cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-[#A31E34] peer-checked:font-montserratExtraBold peer-checked:text-white`}>{error ? "Select" : "No I need an injury attorney"}</label>
                                </div>
                                <div>
                                    <input type="radio" name="option" id={2} className="peer hidden" required onChange={handleFormData("lawyer")} value={'Yes I already have a lawyer/payout for this'} />
                                    <label htmlFor={2} className={`${error ? "text-red-500" : ""} block font-montserratRegular cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-[#A31E34] peer-checked:font-montserratExtraBold peer-checked:text-white`}>{error ? "Select" : "Yes I already have a lawyer/payout for this"}</label>
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className="flex justify-between w-full mt-5">
                        <button variant="primary" className="bg-red-600 font-montserratRegular text-white py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                            Previous
                        </button>
                        <button variant="primary" className="bg-green-600 text-white font-montserratRegular py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                            Continue
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default StepTwo