import React from "react";
import MultiSteps from "./components/multiSteps";

function App() {
  return (
    <>
      <MultiSteps />
    </>
  );
}

export default App;
