import React from "react";

const Final = ({ values, prevStep }) => {
    const {
        accident,
        lawyer,
        fault,
        accidentmessage,
        time,
        firstname,
        lastname,
        tel,
    } = values;

    return (
        <>
            <form>
                <div className="max-w-screen-sm mx-auto bg-white font-montserratRegular p-5 rounded-2xl shadow-[5px_5px_rgba(163,_30,_52,_1),_10px_10px_rgba(163,_30,_52,_0.6),_15px_15px_rgba(163,_30,_52,_0.4),_20px_20px_rgba(163,_30,_52,_0.2),_25px_25px_rgba(163,_30,_52,_0.1)]">
                    {Object.entries(values).map(([key, value]) => (
                        <input key={key} type="hidden" name={key} value={value} />
                    ))}
                    <p>
                        <strong>First Name :</strong> {firstname}{" "}
                    </p>
                    <hr />
                    <p>
                        <strong>Last Name :</strong> {lastname}{" "}
                    </p>
                    <hr />
                    <p>
                        <strong>Phone :</strong> {tel}{" "}
                    </p>
                    <hr />
                    <p>
                        <strong>How Bad was your accident? :</strong> <br /> {accident}{" "}
                    </p>
                    <hr />
                    <p>
                        <strong>Do You already have a lawyer or a payout from this accident?
                            :</strong> <br /> {lawyer}{" "}
                    </p>
                    <hr />
                    <p>
                        <strong>Were you at fault? Be honest :</strong> <br /> {fault}{" "}
                    </p>
                    <hr />
                    <p>
                        <strong>Tell us about your accident :</strong> <br /> {accidentmessage}{" "}
                    </p>
                    <hr />
                    <p>
                        <strong>How long ago did the accident happen? :</strong> <br /> {time}{" "}
                    </p>
                    <hr />
                    <div className="flex justify-between w-full mt-5">
                        <button variant="secondary" className="bg-red-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                            Previous
                        </button>
                        <button variant="primary" className="bg-green-600 text-white font-offside py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                            Send
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Final;
