import React, { useState } from "react";
import validator from "validator";

const StepFour = ({ nextStep, handleFormData, values, prevStep }) => {
    const [error, setError] = useState(false);

    const submitFormData = (e) => {
        e.preventDefault();

        if (
            validator.isEmpty(values.accidentmessage)
        ) {
            setError(true);
        } else {
            nextStep();
        }
    };

    return (
        <>
            <form onSubmit={submitFormData}>
                <div className={'max-w-screen-sm mx-auto bg-white p-5 rounded-2xl shadow-[5px_5px_rgba(163,_30,_52,_1),_10px_10px_rgba(163,_30,_52,_0.6),_15px_15px_rgba(163,_30,_52,_0.4),_20px_20px_rgba(163,_30,_52,_0.2),_25px_25px_rgba(163,_30,_52,_0.1)]'}>
                    <div className="max-w-screen-xl mx-auto text-center">
                        <div className="mb-10">
                            <h2 className="mb-2 text-3xl font-montserratMedium max-lg:text-xl">Tell us about your accident</h2>
                            <span className="font-montserratLight">(4/8)</span>
                        </div>
                        <div>
                            <textarea id="comments" className={`w-full border border-black p-2 ${error ? "placeholder:text-red-500" : ""} font-montserratRegular`} rows={8} required defaultValue={values.accidentmessage} placeholder={error ? (`This is a required field`) : (``)}
                                onChange={handleFormData("accidentmessage")}></textarea>
                        </div>
                    </div>
                    <div className="flex w-full justify-between mt-5">
                        <button variant="primary" className="bg-red-600 text-white font-montserratRegular py-2 px-5 rounded-xl hover:bg-red-800 transition-all duration-300" onClick={prevStep}>
                            Previous
                        </button>
                        <button variant="primary" className="bg-green-600 text-white font-montserratRegular py-2 px-5 rounded-xl hover:bg-green-800 transition-all duration-300" type="submit">
                            Continue
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default StepFour